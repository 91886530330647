import { useState } from "react";
import "./Admin.css";
const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const handleLogin = () => {
    if (username.trim() === "kurdish" && password.trim() === "nottingham2004") {
      const sessionToken = "example_session_token";
      localStorage.setItem("sessionToken", sessionToken);
      window.location.href = "/dashboard";
    } else {
      alert("Invalid credentials.  Please try again :(");
    }
  };
  return (
    <div className="form-container">
      <h2>Login</h2>
      <label className="label">
        Username:
        <input
          className="input"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </label>
      <br />
      <label className="label">
        Password:
        <input
          className="input"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </label>
      <br />
      <button className="button" onClick={handleLogin}>
        Login
      </button>
    </div>
  );
};
export default Login;
