import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ContactUs from "./FrontEnd/page/ContactUs";
import BookNow from "./FrontEnd/page/BookNow";
import Navbar from "./FrontEnd/Component/NavBar";
import "./FrontEnd/Admin/Admin.css";

function Dashboard() {
  return (
    <Router>
      <>
        <Navbar />
        <button
          className="button-LogOut"
          onClick={() => {
            localStorage.removeItem("sessionToken");

            window.location.href = "/dashboard";
          }}
        >
          logout
        </button>
        <Routes>
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="Book_now" element={<BookNow />} />
        </Routes>
      </>
    </Router>
  );
}

export default Dashboard;
