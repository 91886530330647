import Dashboard from "./Dashboard";
import Login from "./FrontEnd/Admin/Admin";

const App = () => {
  const isUserAuthenticated = () => {
    const sessionToken = localStorage.getItem("sessionToken");

    console.log(sessionToken);

    return sessionToken !== null;
  };

  return isUserAuthenticated() ? <Dashboard /> : <Login />;
};

export default App;
