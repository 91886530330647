import { Table, Input } from "antd";
import { useState, useEffect } from "react";
import axios from "axios";
import { Hourglass } from "react-loader-spinner";
const BookNow = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [SearchText, setSearchText] = useState("");
  useEffect(() => {
    GetData();
  }, []);
  const GetData = async () => {
    setLoading(false);
    const res = await axios.get(
      "https://api.kurdishtranslationservices4u.co.uk/api/bookings"
    );

    setDataSource(
      res.data.data.map((row) => ({
        id: row.id,
        full_name: row.full_name,
        phone_number: row.phone_number,
        email: row.email,
        country: row.country,
        subject: row.subject,
        instructions: row.instructions,
        file: row.files.map((e, index) => {
          // eslint-disable-next-line react/jsx-key
          return (
            // eslint-disable-next-line react/jsx-no-target-blank, react/jsx-key
            <a
              target="_blank"
              href={
                "https://api.kurdishtranslationservices4u.co.uk/storage/" +
                e.path
              }
            >
              file {index + 1}, <span> </span>
            </a>
          );
        }),
      }))
    );
  };
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Your Full Name",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
      filteredValue: [SearchText],
      onFilter: (value, record) => {
        return (
          String(record.full_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.id).toLowerCase().includes(value.toLowerCase()) ||
          String(record.phone_number)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.email).toLowerCase().includes(value.toLowerCase()) ||
          String(record.subject).toLowerCase().includes(value.toLowerCase()) ||
          String(record.instructions)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Your Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "instructions",
      dataIndex: "instructions",
      key: "instructions",
    },
    {
      title: "files",
      dataIndex: "file",
      key: "file",
    },
  ];
  return (
    <div>
      <Input.Search
        placeholder="Search here ...."
        style={{
          marginBottom: 15,
          marginTop: 12,
          width: 300,
        }}
        onSearch={(value) => {
          setSearchText(value);
        }}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />
      {loading ? (
        <Hourglass
          visible={true}
          height="80"
          width="80"
          ariaLabel="hourglass-loading"
          wrapperStyle={{}}
          wrapperClass=""
          colors={["#306cce", "#72a1ed"]}
        />
      ) : (
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={{
            defaultPageSize: 6,
          }}
        />
      )}
    </div>
  );
};

export default BookNow;

// for(file in record.files){
//   <Image url=storageUrl+${file.name}></Image>
// }
